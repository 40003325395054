<template>
<section>
<footer class="footer">
<section class="container flex">
<section class="left">
<img class="logo" v-lazy="'/static/img/home/logo-bottom.png'" alt="amjs澳金沙门logo">
<p v-for="(item,index) in company" :key="index">{{item}}</p>
<img class="code" v-lazy="'/static/img/home/code.png'" alt="amjs澳金沙门微信公众号二维码">
<p class="pd">amjs澳金沙门公众号</p>
</section>
<section v-for="item in list" :key="item.key" class="mr-69">
<h5>{{item.name}}</h5>
<a v-for="i in item.list" :key="i.name" :href="i.path" :target="i.path.includes('http')?'_blank':'_self'">{{i.name}}</a>
</section>
</section>
<section class="copyright">
<p class="one">Copyright 2015-2024 amjs澳金沙门 版权所有<a href="https://beian.miit.gov.cn/#/" target="_blank">粤ICP备15098205号</a></p>
<p class="two">申请友链：3475907522</p>
<p class="lianjie"><span class="text">友情链接：</span><a :href="item.path" v-for="(item,index) in friendList" :key="index" target="_blank">{{item.name}}</a></p>
        
      </section>
      
    </footer>

<section class="message flexRow">
<p>本网站相关网页内容未经授权许可，不得复制至其他平台</p>
<a href="/news/detail/474">查看更多<i class="el-icon-arrow-right"></i></a>
</section>
</section>
</template>
<script>
export default {
  name: 'layoutFooter',
  data() {
    return {
      company: [
        '全国客服热线: 400-169-8169 | 020-37267381',
        '邮箱: yewu@weileit.com',
        '广州市天河区棠安路188号303、310室'
      ],
      list: [
        {
          key: 'product', name: '产品中心', list: [
            { name: '高校实验室安全规范管理平台', path: '/product/standard' },
            { name: '实验室安全分级分类管理系统', path: '/product/classify' },
            { name: '实验室综合管理平台', path: '/product/synthetical' },
            { name: '智慧实验室管理平台', path: '/product/wise' },
            { name: '实验室信息管理系统LIMS', path: '/product/lims' },
            { name: '试剂通-SAAS', path: 'http://sj.weileit.com/#/login' },
            { name: '实验室安全管理系统', path: '/product/safety' },
          ]
        },
        {
          key: 'case', name: '成功案例', list: [
            { name: '高校', path: '/case/college' },
            { name: '政府科研机构', path: '/case/institute' },
            { name: '企业/第三方检测', path: '/case/enterprise' },
          ]
        },
        {
          key: 'news', name: '新闻中心', list: [
            { name: '公司新闻', path: '/news/company' },
            { name: '行业新闻', path: '/news/industry' },
          ]
        },
        {
          key: 'cooperate', name: '合作共赢', list: [
            { name: '我是经销商', path: '/cooperate/dealer' },
            { name: '我是厂商', path: '/cooperate/business' },
          ]
        },
        {
          key: 'about', name: '关于amjs澳金沙门', list: [
            { name: '公司简介', path: '/about/company' },
            { name: '荣誉资质', path: '/about/honor' },
            { name: '联系amjs澳金沙门', path: '/about/contact' },
          ]
        },
      ],
      friendList: [
        { name: '实验室安全管理系统', path: '/product/safety' },
        { name: '试剂通', path: 'http://sj.weileit.com/#/login?type=register' },
        { name: '大型仪器共享平台', path: 'http://yqgx.tsinghua.edu.cn/webSite/websiteAction.do?ms=goToIndex' },
        { name: 'MES系统', path: 'http://www.sz-skt.com/' },
        { name: '赫西仪器|实验室离心机', path: 'http://www.hexiyiqi.com/' },
        { name: '实验室设计装修', path: 'http://www.brolab.cn/' },
        { name: '低温试验箱', path: 'http://www.021gwx.com/' },
        { name: '恒温恒湿试验室', path: 'http://www.ywxsh.com/' },
        { name: '手游平台', path: 'http://www.njslhm.com/' },
        { name: '智慧停车', path: 'https://www.zhmkdz.com/' },
        { name: '南京Java培训', path: 'http://www.njjava.com/' },
        { name: '高压细水雾', path: 'http://www.sc-skoll.com/' },
        { name: '招生代理网', path: 'https://www.xue567.com/' },
        { name: 'ntp服务器', path: 'https://www.zjsaisi.com/' },
        { name: '排队系统厂家', path: 'https://www.cdroho.com/' },
        { name: '高频电刀分析仪', path: 'https://www.gmc-medical.cn/' },
        { name: '发电机', path: 'http://www.fdj.biz/' },
        { name: '亚马逊申诉模板', path: 'https://www.kuaijing365.com/' },
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.footer {
  text-align: left;
  width: 100%;
  height: 660px;
  background: #1b1b1e;
  color: #8d8d8f;
  font-size: 16px;
  overflow: hidden;
  .container {
    padding-top: 63px;
    .left {
      width: 420px;
    }
  }
}
p {
  color: #8d8d8f;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 14px;
}
a {
  display: block;
  color: #8d8d8f;
  padding-bottom: 25px;
  line-height: 16px;
  &:hover{
    opacity: 0.9;
  }
}
h5 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #efefef;
  padding-bottom: 42px;
  padding-top: 10px;
}
.logo {
  width: 246px;
  height: 84px;
}
.code {
  margin-top: 18px;
  width: 151px;
  height: 151px;
}
.mr-69 {
  margin-right: 68px;
  &:nth-last-child(1) {
    margin-right: 0;
  }
}
.pd {
  padding-top: 16px;
  padding-bottom: 54px;
}
.copyright {
  border-top: 1px solid #707070;
  width: 1500px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px 0;
  font-weight: 300 !important;
  p {
    padding-bottom: 0;
    color: #d1d1d2;
    display: inline-block;
    font-weight: 300 !important;
    a {
      display: inline-block;
      padding-bottom: 0;
      margin-right: 10px;
      font-weight: 300 !important;
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
  .one {
    margin-right: 44px;
    color: #8d8d8f;
  }
  .two {
    margin-right: 14px;
    a {
      color: #d1d1d2;
    }
  }
}
.message{
  width: 100%;
  height: 55px;
  background: #E6F1FF;
  p{
    font-size: 20px;
    color: #308DFF;
    line-height: 55px;
    padding: 0;
  }
  a{
    width: 94px;
    height: 25px;
    background: #FFFFFF;
    border-radius: 13px;
    color: #308DFF;
    font-size: 16px;
    margin-left: 33px;
    line-height: 25px;
    padding-left: 8px;
  }
}
.lianjie{
  line-height: 40px;
  margin-top: 20px;
  position: relative;
  margin-left: 80px;
  .text{
    position: absolute;
    top: 0;
    left:-80px;
  }
}
</style>
